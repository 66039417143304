export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  base: 'https://dev.connect.techspert.com',
  connectApiBaseUrl: 'https://dev.api.connect.techspert.io',
  searchApiBaseUrl: 'https://dev.api.search.techspert.io',
  searchApiBase2Url: 'https://dev.api.search.techspert.com',
  portalBase: 'https://dev.portal.techspert.com',
  expertPortalBase: 'https://dev.expert.techspert.com',
  salesforceBase: 'https://techspert--developmen.sandbox.lightning.force.com',
  envName: 'dev',
  gaMeasurementId: 'G-HKR8H0VMG2',
  raygunApiKey: 'Ubp8tYWrTCCZsWEhA5swA',
  auth0: {
    domain: 'dev.id.techspert.com',
    clientId: 'jlNAWXdhRNOEqQri6190TmQa0w7egtjS',
    authorizationParams: {
      connection: 'email',
      audience: 'https://dev.api.techspert.io',
      scope: 'openid profile email offline_access',
    },
  },
};
